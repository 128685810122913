<template>
  <div>
    <v-tooltip top v-if="icon.tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-icon style="cursor: pointer"
                :style="`font-size: 1.5rem;color:${icon.color}`"
                @click="clicked(icon)"
                v-bind="attrs"
                v-on="on"
        >
          mdi-{{ icon.class }}

        </v-icon>
      </template>
      <span>{{icon.tooltip}}</span>
    </v-tooltip>

    <v-icon v-else
            style="cursor: pointer"
            :style="`font-size: 1.5rem;color:${icon.color}`"
            @click="clicked(icon)"
    >
      mdi-{{ icon.class }}

    </v-icon>
  </div>
</template>

<script>
import icon from "../plugins/icons";

export default {
  props: [
    'state',
    'action'
  ],
  data() {
    return {
      iconUnknown: {
        class: 'bi-question-square',
        color: 'black'
      }
    }
  },
  methods: {
    clicked(icon) {
      this.$emit('click', icon.class)
      // if(icon.class === 'check' || icon.class === 'alpha-x') {
      //   this.$emit('click', true)
      // } else {
      //   this.$emit('click', icon.class)
      // }

    }
  },
  computed: {
    icon() {
      if (this.state) {
        return icon.states[this.state] ?? this.iconUnknown
      }
      if (this.action) {
        return icon.actions[this.action] ?? this.iconUnknown
      }

      return this.iconUnknown
    }
  },
}
</script>

<style scoped>

</style>