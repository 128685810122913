import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        messanger:{
            message:'',
            color:''
        },
        loading: false,
        language: 'en-US',
        activeMediaTab:null,
        activeVideoTab: null
    },
    mutations: {
        setUser (state, user) {
            state.user = user
        },
        setMessage(state, message){
            state.messanger.message = message.text
            state.messanger.color = message.color
            setTimeout(() => {
                state.messanger.message = ''
            }, 2000);
        },
        setLoading(state, loading){
            state.loading = loading
        },
        setLanguage(state, language){
            state.language = language
        },
        setActiveMediaTab(state, tab){
            state.activeMediaTab = tab
        },
        setActiveVideoTab(state, tab){
            state.activeVideoTab = tab
        }
    },
    actions:{},
    modules:{},
    getters:{
        getUser: (state) => state.user,
        getMessage: (state) => state.messanger,
        getLoading: (state) => state.loading,
        getLanguage: (state) => state.language,
        getActiveMediaTab: (state) => state.activeMediaTab,
        getActiveVideoTab: (state) => state.activeVideoTab,
    }
})
