<template>
  <div>
    <treeselect v-if="categories.length"
                v-model="selectedCategory"
                :multiple="isMultiple"
                :options="categories"
                :searchable="true"
                label="Select Category"
    />
  </div>
</template>

<script>
import CategoryService from "@/services/categoryService";
import Treeselect from "@riophae/vue-treeselect";
import './categoryDropdown.css'

export default {
  components: {Treeselect},
  props: [
    'preselected',
    'multiple'
  ],
  data() {
    return {
      categories: [],
      selectedCategory: this.preselected,
      isMultiple: this.multiple ?? false
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    getCategories() {
      CategoryService.list({tree: true})
          .then(response => {
            let categories = response.data.data
            categories.forEach(category => {
              if (category.parent_id === 0) {
                let cat = {
                  id: category.id,
                  label: category.title,
                  children: [],
                }
                if (category.children.length) {
                  category.children.forEach(child => {
                    let c = {
                      id: child.id,
                      label: child.title,
                    }
                    cat.children.push(c)
                  })
                }
                this.categories.push(cat)
              }
            })
          })
    }
  },
  watch: {
    selectedCategory(val) {
      this.$emit('selected', val)
    }
  }
}

</script>

<style scoped>

</style>