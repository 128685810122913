import axios from "../plugins/axios";

export default {
    list: (params) => axios.post('/admin/articles', params),
    get: (id) => axios.get('/admin/articles/' + id),
    getParagraphs: (id) => axios.get('/admin/articles/paragraphs/' + id),
    getHits: (ids) => axios.post('/admin/articles/hits', {ids}),
    update: (item) => axios.put('/admin/articles/' + item.id, item),
    sendNotification: (id) => axios.get('/admin/articles/notification/' + id),
    upload: (id, formData) => axios.post('/admin/articles/' + id + '/upload', formData),
    detachMedia: (id, media_id) => axios.delete('/admin/articles/' + id + '/detach-media/' + media_id),
    updateMediaCaption: (id, params) => axios.put('/admin/articles/media/' + id + '/caption', params),
}
