<template>
  <editor v-if="content" id="tinymce" v-model="content" :init="init"></editor>
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide-dark/skin.css";
import 'tinymce/skins/content/dark/content.min.css';

import Editor from "@tinymce/tinymce-vue";

import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualchars';
// import 'tinymce/plugins/template';
import 'tinymce/plugins/codesample';
// import 'tinymce/plugins/hr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/toc';
// import 'tinymce/plugins/imagetools';
// import 'tinymce/plugins/textpattern';
// import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/autosave';
import 'tinymce/models/dom'

export default {
  props:['textContent'],
  components: {
    editor: Editor
  },
  data() {
    return {
      // tmc:tinymce,
      content: this.textContent,
      init: {
        height: 500,
        skin: false,
        content_css: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
        branding: false,
        menubar: false,


      }
    };
  },
  watch:{
    content(val){
      this.$emit('contentUpdated', val)
    }
  }
};
</script>