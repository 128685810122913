<template>
  <div>
    <div class="row d-flex justify-content-end">
      <div class="col-4  col-md-2">
        <v-btn

            @click="openUploader">
          Upload
        </v-btn>
      </div>
    </div>

    <div class="row" v-if="items">
      <template v-for="item in items">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-if="item.type == 1" :key="item.id">
          <v-card elevation="4" outlined class="h-100">

            <div class="row d-flex justify-content-end">
              <div class="col-3">
              <span class="d-block m-3 text-center p-1 text-white "
                    :class="leadImage === item.id ? 'bg-danger' : ''">Lead</span>
              </div>
            </div>
            <v-card-text>
              <v-img :src="item.gcs_serve" class="w-100"></v-img>
              <v-text-field v-model="item.params.source"
                            :key="item.id"
                            label="Caption"
                            append-icon="mdi-content-save"
                            @click:append="saveMediaSource(item)"
              ></v-text-field>
            </v-card-text>
            <v-card-title>{{ item.params.source }}</v-card-title>

            <v-card-actions>

              <v-btn
                  color="purple lighten-2"
                  text
                  @click="markAsLead(item)"
              >
                Lead
              </v-btn>

<!--              :disabled="leadImage === item.id"-->
              <v-btn
                  color="red lighten-2"
                  text

                  @click="deleteItem(item)"
              >
                Delete
              </v-btn>
            </v-card-actions>

          </v-card>
        </div>
      </template>
    </div>

    <file-uploader v-if="showUpload"
                   :show="showUpload"
                   :content="this.content"
                   @close="closeUpload"
                   @uploadFinished="uploadFinished"
    ></file-uploader>

  </div>
</template>

<script>
import FileUploader from "@/components/utility/FileUploader.vue";
import ArticleService from "@/services/articleService";

export default {
  components: {FileUploader},
  props: ['media', 'leadImage', 'content'],
  data() {
    return {
      items: this.media,
      lead: this.leadImage,
      showUpload: false,
      showCaption: false
    }
  },
  mounted() {
    this.items.forEach( item =>{
      if(typeof item.params === 'string'){
        item.params = JSON.parse(item.params)
      }
    })

  },
  methods: {
    deleteItem(item) {
      this.$emit('deleteItem', item)
    },
    markAsLead(item) {
      this.$emit('markAsLead', item)
    },
    openUploader() {
      this.showUpload = true
    },
    closeUpload() {
      this.showUpload = false
    },
    uploadFinished(content) {
      this.items = content.media
      this.$emit('onContentChange', content)
      this.closeUpload()
    },
    saveMediaSource(item) {
      this.$store.commit('setLoading', true)
      ArticleService.updateMediaCaption(item.id, item)
          .then(() => {
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    }
  }
}
</script>

<style scoped>

</style>