<template>

  <v-treeview
      v-if="selected"
      open-on-click
      return-object
      expand-icon="mdi-chevron-down"
      indeterminate-icon="mdi-bookmark-minus"
      dense
      :items="categories"
  >
    <template v-slot:label="props">
      <div class="treeCheckBox">
        <v-checkbox class="treeCheckBox"
                    color="indigo"
                    v-model="props.item.selected"
                    :label="props.item.name">
        </v-checkbox>
      </div>
    </template>

  </v-treeview>
</template>

<script>
import CategoryService from "@/services/categoryService";

export default {
  props: {
    preselected:{
      type: Array,
      require: true
    }
  },
  data() {
    return {
      categories: [],
      selected: this.preselected
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    getCategories() {
      CategoryService.list({tree: true})
          .then(response => {
            let categories = response.data.data
            this.categories = []
            categories.forEach(category => {

              if (category.parent_id === 0) {
                let cat = {
                  id: category.id,
                  name: category.title,
                  children: [],
                  selected: this.preselected.includes(category.id)
                }
                if (category.children.length) {
                  category.children.forEach(child => {
                    let c = {
                      id: child.id,
                      name: child.title,
                      selected: this.preselected.includes(child.id)
                    }
                    cat.children.push(c)
                  })
                }
                this.categories.push(cat)
              }
            })
          })
    }
  }
}
</script>

<style scoped>
.treeCheckBox {
  margin-top: 0px;
  max-height: 30px;
}
</style>