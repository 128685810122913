<template>
  <div>
    <template v-if="media">
      <v-tabs
          v-model="tab"
          bg-color="primary"
          align-with-title
      >
        <v-tab value="images">Images</v-tab>
        <v-tab value="video">Video</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item key="images">
          <content-image-list :media="item.media"
                              :content="this.item"
                              :leadImage="leadImage"
                              @markAsLead="markAsLead"
                              @deleteItem="deleteItem"
                              @onContentChange="onContentChange"
          />
        </v-tab-item>

        <v-tab-item key="video">
          <content-video-list :media="content.media"
                              :content="this.item"
                              @videoSelected="videoSelected"
                              @deleteItem="deleteItem"
          />
        </v-tab-item>

      </v-tabs-items>


    </template>
  </div>
</template>

<script>
import ContentImageList from "@/components/article/ContentImageList.vue";
import ContentVideoList from "@/components/article/ContentVideoList.vue";
import ArticleService from "@/services/articleService";


export default {
  components: {ContentVideoList, ContentImageList},
  props: ['content'],
  data() {
    return {
      tab: this.$store.state.activeMediaTab ?? null,
      item: this.content,
      media: null,
      leadImage: null
    }
  },
  mounted() {
    this.media = this.item.media
    this.item.media.forEach(i => {
      if (typeof i.params === 'string') {
        i.params = JSON.parse(i.params)
      }
    })
    let params = this.item.params
    if (params.image_ordering) {
      this.leadImage = params.image_ordering[0]
    }
  },
  created() {

  },
  computed: {},
  methods: {
    videoSelected() {
      this.$emit('update', this.item)
    },
    deleteItem(item) {
      this.$store.commit('setLoading', true)
      let mediaBackup = this.media
      this.media = null
      ArticleService.detachMedia(this.item.id, item.id)
          .then((response) => {
            this.item = response.data
            this.media = this.item.media
            if (this.item.params.image_ordering) {
              this.leadImage = this.item.params.image_ordering[0]
            }
            this.$emit('update', response.data)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: 'Media deleted', color: 'success'})
          })
          .catch(err => {
            console.log(err)
            this.media = mediaBackup
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    markAsLead(item) {
      if (!this.item.params.image_ordering && this.item.media.length) {
        this.item.params.image_ordering = []
        this.item.media.forEach(m => {
          if (m.gcs_serve) {
            this.item.params.image_ordering.push(m.id)
          }
        })
      }
      let sorted = this.item.params.image_ordering.filter(i => {
        return i !== item.id
      })
      Array.prototype.unshift.apply(sorted, [item.id]);
      this.item.params.image_ordering = sorted
      this.leadImage = item.id
      this.$emit('update', this.item)
    },
    onContentChange(item) {
      this.item = item
      this.$emit('update', item)
    }
  },
  watch: {
    tab() {
      this.$store.commit('setActiveMediaTab', this.tab)
    }
  }
}
</script>


<style scoped>

</style>