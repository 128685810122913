<template>
  <div>
    DASHBOARD
  </div>
</template>

<script>

export default {
  data(){
    return {

    }
  },
  mounted(){

  }
}
</script>

<style scoped>

</style>
