<template>
  <div>
    <template v-if="media">
      <v-tabs
          v-model="tab"
          bg-color="primary"
          align-with-title
      >
        <v-tab value="betavideo">Beta Video</v-tab>
        <v-tab value="youtube">YouTube</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">

        <v-tab-item key="betavideo">

          <div class="row d-flex justify-content-end">
            <div class="col-4  col-md-2">
              <beta-tv-video-dialog :open="dialog"
                                    @selected="videoSelected"
              />
            </div>
          </div>

          <div class="row mt-3" v-if="items">
            <template v-for="item in items">
              <div class="col-3" v-if="item.type == 2" :key="item.id">
                <v-card elevation="2" class="h-100">

                  <video class="w-100" controls :src="item.gcs_serve ?? item.url"></video>
                  <v-card-title>{{ item.source }}</v-card-title>

                  <v-card-actions>
                    <v-btn
                        color="red lighten-2"
                        text
                        @click="deleteItem(item)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>

                </v-card>
              </div>
            </template>
          </div>
        </v-tab-item>

        <v-tab-item key="youtube">
          <beta-youtube-video-list v-if="items"
                                   :media="items"
                                   @videoSelected="videoSelected"
                                   @deleteItem="deleteItem"
          />

        </v-tab-item>

      </v-tabs-items>


    </template>

  </div>
</template>

<script>
import BetaTvVideoDialog from "@/components/article/BetaTvVideoDialog.vue";
import BetaYoutubeVideoList from "@/components/article/BetaYoutubeVideoList.vue";


export default {
  components: {BetaYoutubeVideoList, BetaTvVideoDialog},
  props: ['media'],
  data() {
    return {
      items: this.media,
      tab: this.$store.state.activeVideoTab ?? null,
      dialog: false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('deleteItem', item)
    },
    videoSelected(items) {
      items.forEach(i => {
        let exists = this.items.filter(item => {
          if (item.type === 2) {
            return item.id === i.id || item.filename === i.id + '.mp4'
          } else if (item.type === 3) {
            item.path = 'https://www.youtube.com/embed'
            return item.filename === i.filename || item.filename === i.id
          }
        })
        if (!exists.length) {
          this.items.push(i)
        }
      })
      console.log(items);
      this.$emit('videoSelected', items)
    }
  },
  watch:{
    tab(){
      this.$store.commit('setActiveVideoTab', this.tab)
    }
  }
}
</script>

<style scoped>

</style>