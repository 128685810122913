<template>
  <treeselect v-if="menu"
              v-model="selectedMenu"
              :multiple="false"
              :options="menu"
              :searchable="true"
              label="Select Menu"
  />
</template>

<script>

import Treeselect from "@riophae/vue-treeselect";
import './categoryDropdown.css'
import MenuService from "@/services/menuService";

export default {
  components: { Treeselect },
  props: [
      'preselected'
  ],
  data() {
    return {
      menu: [],
      selectedMenu: this.preselected
    }
  },
  mounted() {
    this.getmenu()
  },
  methods: {
    getmenu() {
      MenuService.list({tree: true})
          .then(response => {
            let menu = response.data.data

            menu.forEach(category => {

              if (category.parent_id === 0) {
                let cat = {
                  id: category.id,
                  label: category.title,
                  children: [],
                }
                if (category.children.length) {
                  category.children.forEach(child => {
                    let c = {
                      id: child.id,
                      label: child.title,
                    }
                    cat.children.push(c)
                  })
                }
                this.menu.push(cat)
              }
            })
          })
    }
  },
  watch:{
    selectedMenu(val){
      console.log(val);
      this.$emit('selected', val)
    }
  }
}

</script>

<style scoped>

</style>