<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
      >
        Add Video
      </v-btn>
    </template>


    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Video</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="save"
          >
            Add
          </v-btn>
          <v-btn
              dark
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div v-if="items" class="p-5">

        <div class="row">
<!--          <div class="col-4">-->
<!--            <v-text-field-->
<!--                label="Search"-->
<!--                append-icon="mdi-magnify"-->
<!--                @input="filterTerm"-->
<!--                :value="filter.term"-->
<!--                append-outer-icon="mdi-minus-circle-outline"-->
<!--                @click:append-outer="clearTerm"-->
<!--            ></v-text-field>-->
<!--          </div>-->

          <div class="col-4">
            <v-select
                v-if="services"
                :items="services"
                v-model="filter.service"
                label="Services"
                item-text="label"
                item-value="id"
                @change="searchServices"
            ></v-select>
          </div>

        </div>

        <div class="row">
          <template v-for="item in items">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" :key="item.id">
              <beta-tv-video-card :video="item"
                                  class="h-100"
              />
            </div>
          </template>

        </div>

        <div class="row">
          <div class="col-12">
            <v-pagination
                v-if="items"
                circle
                light
                v-model="filter.page"
                :length="filter.totalPages"
                :total-visible="7"
            ></v-pagination>
          </div>
        </div>

      </div>

    </v-card>


  </v-dialog>
</template>

<script>
import BetaTvVideoService from "@/services/betaTvVideoService";
import BetaTvVideoCard from "@/components/article/BetaTvVideoCard.vue";

export default {
  components: {BetaTvVideoCard},
  props: ['open'],
  data() {
    return {
      dialog: this.open,
      items: null,
      selected: [],
      searchTimer: null,
      services:[],
      filter: {
        service: null,
        term: '',
        page: 1,
        perPage: 20,
        totalRows: 0
      }
    }
  },
  mounted() {

  },
  methods: {
    getList() {
      this.$store.commit('setLoading', true)
      BetaTvVideoService.list(this.filter)
          .then(resp => {
            this.filter.totalPages = resp.data.last_page;
            this.items = resp.data.data.map( r => {
              r.selected = false
              return r
            })
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    getServices(){
      BetaTvVideoService.services()
          .then(resp => {
           resp.data.forEach( r => {
             this.services.push({
               'label': r.servis_naziv,
               'id': r.IDservis
             })
           })
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    save(){
      let selected = this.items.filter( i => {
        return i.selected
      })
      if(selected.length){
        this.$emit('selected', selected)
      }
      this.dialog = false
    },
    searchServices(){
      clearTimeout(this.searchTimer)
      this.getList()
    },
    filterTerm(val) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.filter.term = val
        this.filter.page = 1
        this.getList()
      }, 500);
    },
    clearTerm(){
      clearTimeout(this.searchTimer)
      this.filter.term = ''
      this.getList()
    }
  },
  watch:{
    dialog(state){
      if(state) {
        this.items = null
        this.getServices()
        this.getList()
      }
    },
    "filter.page"(){
      clearTimeout(this.searchTimer)
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>