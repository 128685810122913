<template>
  <div v-if="content">
    <div class="row">

      <div class="col-12 col-lg-4">
        <v-text-field v-model="content.title"
                      label="Title"
                      clearable
        ></v-text-field>
      </div>

      <div class="col-12 col-lg-4">
        <v-text-field v-model="content.alias"
                      label="Alias"
                      readonly
                      disabled
        ></v-text-field>
      </div>

      <div class="col-12 col-lg-4">
        <div class="row">
          <div class="col-4">
            <v-switch v-model="state" label="State"/>
          </div>

          <div class="col-12 col-md-8">
            <v-card elevation="2" >
              <v-card-text>
                <v-switch v-model="content.flags.front"
                          label="Fronpage"
                          dense
                          class="mt-0"
                          :true-value="1"
                          :false-value="0"
                />
                <v-switch v-model="content.flags.galsort"
                          label="Top news"
                          dense
                          class="mt-0"
                          :true-value="1"
                          :false-value="0"
                />
                <v-switch v-model="content.flags.izbor"
                          label="Editor choose"
                          dense
                          class="mt-0"
                          :true-value="1"
                          :false-value="0"
                />
              </v-card-text>
            </v-card>
          </div>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <div class="col-form-label">Category</div>
        <category-dropdown
            :preselected="content.category[0].id"
            @selected="isCategory"
        />

      </div>
      <div class="col-12 col-md-4">
        <v-text-field v-model="content.created_at"
                      label="Created At"

        ></v-text-field>
      </div>
      <div class="col-12 col-md-4">
        <v-text-field v-model="content.updated_at"
                      label="Updated At"

        ></v-text-field>
      </div>
    </div>

    <div class="col-12">
      <editor
          :textContent="content.fulltext"
          @contentUpdated="contentUpdated"
      />
    </div>

  </div>
</template>

<script>

import CategoryDropdown from "@/components/category/categoryDropdown.vue";
import Editor from '@/components/AdminEditor.vue'

export default {
  components: {
    CategoryDropdown,
    Editor
  },
  props: ['item'],
  data() {
    return {
      content: this.item,
      editor: false
    }
  },
  computed: {
    state: {
      get() {
        return this.content.state === 1 ? true : false
      },
      set(val) {
        this.content.state = val ? 1 : 0
      }
    }
  },
  mounted() {

  },
  methods: {
    isCategory(val) {
      this.content.category_id = val
    },
    contentUpdated(val){
      this.content.fulltext = val
    }
  }
}
</script>