import axios from "../plugins/axios";

export default {
    list: (params) => axios.get('/admin/modules?nocache', {params}),
    get: (id) => axios.get('/admin/modules/' + id),
    moduleables: (params) => axios.get('/admin/modules/moduleables?nocache', {params}),
    moduleableDuplicate: (id) => axios.post('/admin/modules/moduleables/duplicate?nocache', {id}),
    moduleableDelete: (id) => axios.delete('/admin/modules/moduleables/' + id + '?nocache'),
    moduleable: (id) => axios.get('/admin/modules/moduleable/' + id),
    moduleableTypes: () => axios.get('/admin/modules/moduleable/types?nocache'),
    moduleablePositions: (params) => axios.get('/admin/modules/moduleable/positions?nocache', {params}),
    moduleablePositionsSave: (params) => axios.post('/admin/modules/moduleable/positions?nocache', params),
    moduleablePositionsUpdate: (params) => axios.put('/admin/modules/moduleable/positions/' + params.id + '?nocache', params),
}
