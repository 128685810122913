const Routes = [
    { path: '/', name: 'home', component: () => import('../views/AdminDashboard.vue') },
    { path: '/login', name: 'login', component: () => import('../views/AdminLogin.vue') },

    // Modules
    { path: '/modules', name: 'modules', component: () => import('../views/modules/AdminModules.vue') },
    { path: '/modules/:id', name: 'module-detail', component: () => import('../views/modules/AdminModulesDetail.vue') },
    { path: '/modules/positions/create', name: 'module-positions-detail', component: () => import('../views/modules/AdminModulePositionsCreate.vue') },
    { path: '/modules/positions/:id', name: 'module-positions-detail', component: () => import('../views/modules/AdminModulePositions.vue') },

    //Articles
    { path: '/articles', name: 'articles', component: () => import('../views/articles/ArticleList') },
    { path: '/articles/:id', name: 'article', component: () => import('../views/articles/ArticleEdit.vue') },

    //RssUsers
    { path: '/rssUsers', name: 'rssUsers', component: () => import('../views/rssUsers/rssUserList.vue') },
    { path: '/rssUsers/:id', name: 'rssUsers', component: () => import('../views/rssUsers/rssUserDetail.vue') },
]

export default Routes