<template>
  <div v-if="media">
    <div class="row d-flex justify-content-end">
      <div class="col-4  col-md-2">
        <beta-you-tube-dialog :open="dialog"
                              @selected="videoSelected"
        />
      </div>
    </div>

    <div class="row mt-3" v-if="items">
      <template v-for="item in items">
        <div class="col-3" v-if="item.type == 3" :key="item.id">
          <v-card elevation="2" class="h-100">
            <iframe width="100%" height="315" :src="item.url"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            <!--          <video class="w-100" controls :src="item.gcs_serve ?? item.url"></video>-->
            <v-card-title>{{ item.source }}</v-card-title>

            <v-card-actions>
              <v-btn
                  v-if="item.id"
                  color="red lighten-2"
                  text
                  @click="deleteItem(item)"
              >
                Delete
              </v-btn>
            </v-card-actions>

          </v-card>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import BetaYouTubeDialog from "@/components/article/BetaYouTubeDialog.vue";

export default {
  components: {BetaYouTubeDialog},
  props: ['media'],
  data() {
    return {
      items: null,
      dialog: false
    }
  },
  mounted() {
    this.items = this.media.filter(m => m.type === 3)
  },
  methods: {
    videoSelected(items) {

      this.items.newMedia = []

      items.forEach(i => {

        let exists = this.items.filter(item => {
          return item.filename === i.filename
        })
        if (!exists.length) {
          i.type = 3
          this.items.push(i)
        }

      })
      this.$emit('videoSelected', this.items)
    },
    deleteItem(item) {
      this.$emit('deleteItem', item)
    }
  }
}
</script>

<style scoped>

</style>