<template>
  <div>

    <v-row>
      <v-col cols="4">
        <v-text-field
            v-model="rssUser.user"
            label="Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <category-dropdown
            v-if="categories.length"
            :preselected="categories"
            @selected="onCategorySelect"
            multiple
        ></category-dropdown>
      </v-col>

      <v-col cols="4">
        <v-switch
            v-model="rssUser.state"
            label="Active"
            hide-details
        ></v-switch>
      </v-col>

    </v-row>

    <v-row v-if="userToken">
      <v-col cols="12">
        <v-card variant="tonal">
          <v-card-title>User Code</v-card-title>
          <v-card-text v-html="userCode"></v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import RssUserService from "@/services/rssUserService";
import CategoryDropdown from "@/components/category/categoryDropdown.vue";

export default {
  components: {CategoryDropdown},
  data() {
    return {
      rssUser: {},
      userToken: null,
      categories:[],
      selectedCategories:null
    }
  },
  mounted() {
    this.getUser()
    this.getToken()
  },
  computed:{
    userCode(){
      return '{<br>' +
          '&emsp;"Headers":<br>' +
          '&emsp;&emsp;{<br>' +
          '&emsp;&emsp;&emsp;"Authorization": "Bearer ' + this.userToken + '"<br>' +
          '&emsp;&emsp;},<br>' +
          '&emsp;"METHOD":"GET",<br>' +
          '&emsp;"URL":" https://beta.rs/rss"<br>' +
          '}'
    }
  },
  methods: {
    getUser() {
      this.$store.commit('setLoading', true)
      RssUserService.getUser(this.$route.params.id)
          .then(response => {
            this.$store.commit('setLoading', false)
            this.rssUser = response.data
            this.categories = this.rssUser.categories.map( item => { return item.id })
            this.selectedCategories = this.categories
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            console.log(err)
          })
    },
    getToken(){
      RssUserService.getUserToken(this.$route.params.id)
          .then(response => {
            this.userToken = response.data
          })
          .catch(err => {

            console.log(err)
          })
    },
    onCategorySelect(data){
      this.selectedCategories = data;
    }
  }
}
</script>

