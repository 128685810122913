<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
      >
        Add Video
      </v-btn>
    </template>


    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add YouTube Video</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="save"
          >
            Add
          </v-btn>
          <v-btn
              dark
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div v-if="items" class="p-5">
        <v-tabs
            v-model="tab"
            bg-color="primary"
            align-with-title
        >
          <v-tab value="beta">Beta YouTube</v-tab>
          <v-tab value="custom">Custom YouTube</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="beta">
            <div class="row">
              <div class="col-4">
                <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    @input="filterTerm"
                    :value="filter.term"
                    append-outer-icon="mdi-minus-circle-outline"
                    @click:append-outer="clearTerm"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <template v-for="item in items">
                <div class="col-12 col-md-6 col-lg-4 col-xl-3" :key="item.id">

                  <beta-youtube-card :video="item"
                                     class="h-100"
                                     @itemSelected="itemSelected"
                  />

                </div>
              </template>

            </div>
            <div class="row">
              <div class="col-12">
<!--                <v-pagination-->
<!--                    v-if="items"-->
<!--                    circle-->
<!--                    light-->
<!--                    v-model="filter.page"-->
<!--                    :length="filter.totalPages"-->
<!--                    :total-visible="7"-->
<!--                ></v-pagination>-->
              </div>
            </div>
          </v-tab-item>
          <v-tab-item key="custom">

            <div class="row">
              <div class="col-4">
                <v-text-field
                    label="YouTube URL"
                    append-icon="mdi-magnify"
                    v-model="filter.customUrl"
                    append-outer-icon="mdi-minus-circle-outline"
                    @click:append-outer="clearCustomUrl"
                    hint="Enter to search"
                    @keydown="searchCustomYouTube"
                ></v-text-field>
              </div>


                <div class="row" v-if="customItems">
                  <template v-for="item in customItems">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3" :key="item.id">

<!--                      <beta-youtube-card :video="item"-->
<!--                                         class="h-100"-->
<!--                                         @itemSelected="itemSelected"-->
<!--                      />-->
                      <beta-youtube-card :video="item"
                                         class="h-100"
                      />
                    </div>
                  </template>

                </div>


            </div>

          </v-tab-item>
        </v-tabs-items>


      </div>

    </v-card>


  </v-dialog>
</template>

<script>

import BetaYouTubeService from "@/services/betaYouTubeService";
import BetaYoutubeCard from "@/components/article/BetaYoutubeCard.vue";

export default {
  components: {BetaYoutubeCard},
  props: ['open'],
  data() {
    return {
      dialog: this.open,
      tab: null,
      test:1,
      items: null,
      customItems: null,
      selected: [],
      searchTimer: null,
      filter: {
        service: null,
        term: '',
        page: 1,
        perPage: 20,
        totalRows: 0,
        customUrl: null
      }
    }
  },
  mounted() {

  },
  methods: {
    getList() {
      this.$store.commit('setLoading', true)
      BetaYouTubeService.list(this.filter)
          .then(resp => {

            this.items = resp.data.items.map(r => {
              r.selected = false
              return r
            })

            let pageInfo = resp.data.pageInfo
            this.filter.totalPages = Math.ceil(pageInfo.totalResults / pageInfo.resultsPerPage)
            this.filter.nextPageToken = resp.data.nextPageToken
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    save() {

      let selected = this.items.filter(i => {
        return i.selected
      })

      if(this.customItems) {
        let customSelected = this.customItems.filter(i => {
          return i.selected
        })
        selected.push(...customSelected)
      }
      if (selected.length) {
        this.$emit('selected', selected)
      }
      this.dialog = false
    },
    itemSelected(item){

      this.items.forEach(i => {
        if(i.filename === item.id || i.id === item.filename){
          i.selected = item.selected
        }
      })
      // this.$emit('itemsSelected', this.items)
    },
    searchServices() {
      clearTimeout(this.searchTimer)
      this.getList()
    },
    filterTerm(val) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.filter.term = val
        this.filter.page = 1
        this.getList()
      }, 500);
    },
    clearTerm() {
      clearTimeout(this.searchTimer)
      this.filter.term = ''
      this.getList()
    },
    searchCustomYouTube(key){
      if(key.key === 'Enter') {
        BetaYouTubeService.listCustom(this.filter.customUrl)
            .then(resp => {

              this.customItems = resp.data.items.map(r => {
                r.selected = false
                return r
              })
              this.$store.commit('setLoading', false)
            })
            .catch(err => {
              this.$store.commit('setLoading', false)
              this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
            })
      }
    },
    clearCustomUrl(){
      this.filter.customUrl = ''
    }
  },
  watch: {
    dialog(state) {
      if (state) {
        this.items = null
        this.getList()
      }
    },
    "filter.page"() {
      clearTimeout(this.searchTimer)
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>