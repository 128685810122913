<template>
  <div>
    <v-simple-table v-if="listItems.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th
              v-for="h in header"
              :key="h.field"
              @click="sorting(h)"
              :style="h.sortable ? 'cursor:pointer' : ''"
          >
            {{ h.name }}
            <v-icon
                v-if="h.sortable && h.sortDirection"
                style="cursor: pointer"
                small
            >
              {{ activeSortIcon }}
            </v-icon>
          </th>
        </tr>
        </thead>

        <tbody>
        <template v-for="item in listItems">
          <tr :key="item.id">
            <td v-for="h in header" :key="h.field">
              <span v-if="h.field === 'state'">
                <mdi-icon :state="item.state" @click="actionClicked"/>
              </span>

              <a v-else-if="h.link"
                 :href="link(h.link, item)"
              >
                <span v-if="!item[h.field]">Untitled</span>
                <span v-else>{{ item[h.field] }}</span>

              </a>

              <span v-else-if="h.type === 'relation'">
                {{ item[h.field] }}
              </span>

              <span v-else-if="h.type === 'flag:media'">
                <v-icon
                    v-if="resolveMediaFlag(item, 1)"
                    class="text-info"
                >mdi-image</v-icon>
                <v-icon
                    v-if="resolveMediaFlag(item, 2)"
                    class="text--secondary"
                >mdi-video</v-icon>
                <v-icon
                    v-if="resolveMediaFlag(item, 3)"
                    class="text-danger"
                >mdi-youtube</v-icon>
              </span>
              <span v-else-if="h.field === 'sent'">
                <v-btn v-if="!item.sent"
                       density="compact"
                       @click="onSendNotification(item)"
                       class="green--text"
                >
                  Send notification</v-btn>
                <span v-else class="red--text">Already sent</span>
              </span>

              <span v-else-if="h.field.startsWith('actions')">
                <div class="row justify-content-start">
                    <div class="col-1 mr-2" v-for="action in getActions(h.field)" :key="action">
                      <mdi-icon :action="action" @click="actionClicked(item.id, action)" />
                    </div>
                </div>
              </span>
              <span v-else class="animate-on-change" :key="h.field + item[h.field]">
                <template v-if="h.type === 'date'">
                  {{ item[h.field]|moment('DD.MM.YYYY HH:mm:ss') }}
                </template>
                <template v-else>
                  {{ item[h.field] }}
                </template>
              </span>
            </td>
          </tr>
        </template>

        </tbody>

      </template>
    </v-simple-table>


    <div class="col-12 mb-3">
      <div class="text-center mt-5">
        <v-pagination
            v-if="listPagination"
            circle
            light
            v-model="listPagination.page"
            :length="listPagination.last_page"
            :total-visible="7"
        ></v-pagination>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      listItems: [],
      listPagination: this.pagination,
      sortBy: 'id',
      sortByDirection: 'desc',
      sortDescIcon: 'mdi-arrow-down',
      sortAscIcon: 'mdi-arrow-up',
      activeSortIcon: null
    }
  },
  mounted() {
    this.activeSortIcon = this.sortDescIcon
    this.resolveItems()
  },
  methods: {
    resolveItems() {
      this.listItems = []
      this.items.forEach(item => {

        let fields = {}
        this.header.forEach(h => {
          let itemData = item[h.field]
          if (h.field.includes('.')) {
            itemData = this.resolveObjectPath(h, item);
          }
          fields = Object.assign({
            [h.field]: h.field === 'state' ? this.itemState(item.state) : itemData
          }, fields)
        })

        this.listItems.push(fields)
      })
    },
    itemState(val) {
      return val === 1 ? 'published' : 'unpublished'
    },
    link(data, item) {
      const paramsPattern = /[^{}]+(?=})/g;
      let params = data.match(paramsPattern)
      params.forEach(p => {
        data = data.replace('{' + p + '}', item[p])
      })
      return data
    },
    resolveMediaFlag: (item, type) => {
      if (!item.media) {
        return '';
      }

      return item.media.filter(m => m.type === type).length > 0
    },
    resolveObjectPath(objPath, obj) {

      if (objPath.type === 'relation') {
        let path = objPath.field.split('.')

        if (path[0] && obj[path[0]].length) {

          let data = obj[path[0]].map(p => {
            return p[path[1]]
          })

          return data[0] ?? 'unknown'
        }
      } else {
        return objPath.field.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null
        }, obj || self)
      }
    },
    onSendNotification(item) {
      this.$emit('onNotification', item)
    },
    sorting(item) {
      let sortBy = this.header.filter(h => {
        return h.field === item.field
      })[0]

      this.header.forEach(h => {
        if (sortBy.field !== h.field) {
          delete h.sortDirection
        }
      })


      if (!sortBy.sortDirection) {
        sortBy.sortDirection = 'asc'
      }

      if (sortBy.sortDirection === 'asc') {
        sortBy.sortDirection = 'desc'
        this.activeSortIcon = this.sortDescIcon
      } else {
        sortBy.sortDirection = 'asc'
        this.activeSortIcon = this.sortAscIcon
      }
      this.$emit('sortBy', sortBy)
    },
    getActions(items) {
      let actions = (items.split(':'))
      return actions[1].split(',')
    },
    actionClicked(id, action){
      this.$emit('action', {id: id, action:action})
    }
  },
  watch: {
    listPagination: {
      deep: true,
      handler() {
        this.$emit('page', this.listPagination)
      }
    },
    items: {
      deep: true,
      handler() {
        this.resolveItems()
      }
    }
  }
}
</script>

<style scoped>
.animate-on-change {
  background-color: white;
  color: black;
  animation: animatedChange 3s;
  padding: 0.2rem;
}

@keyframes animatedChange {
  from {
    background-color: red;
    color: white;
    font-weight: bold;
  }
  to {
    background-color: white;
    color: black;
    font-weight: normal;
  }
}

</style>