<template>
  <div>
    {{ dialog }}

    <v-dialog
        v-if="dialog"
        v-model="dialog"
        persistent
        max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            PERA

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>

          <v-btn
              color="green darken-1"
              text
              @click="cancel"
          >
            Cancel
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      dialog: this.value
    }
  },
  watch:{
    value(val){
      this.dialog = val
    }
  },
  methods:{
    save(){
      this.$emit('save', null)
    },
    cancel(){
      this.$emit('cancel', null)
    }
  }
}
</script>

<style scoped>

</style>