<template>
  <div class="container-fluid">
    <list-toolbar @action="actions"
    ></list-toolbar>
    <v-card flat>
      <v-card-text>

        <div class="row justify-content-center">

          <div class="col-4">
            <v-text-field
                label="Search"
                append-icon="mdi-magnify"
                v-model="searchTerm"
            ></v-text-field>
          </div>

          <div class="col-4">
            <v-select
                v-model="selectedType"
                :items="types"
                label="Type"
            ></v-select>
          </div>

        </div>

        <admin-table v-if="moduleables.length"
                     :header="table.moduleables_header"
                     :items="moduleables"
                     :pagination="filter"
                     @page="page"
                     @action="triggerAction"
        />

      </v-card-text>
    </v-card>


  </div>


</template>

<script>
import ModuleService from "@/services/moduleService";
import AdminTable from "@/components/AdminTable";
import ListToolbar from "@/components/toolbar/ListToolbar";

export default {
  components: {ListToolbar, AdminTable},
  data() {
    return {
      moduleables: [],
      searchTerm: null,
      filter: {
        term: null,
        type: null,
        page: 1,
        limit: 20,
        last_page: 0
      },
      table: {
        moduleables_header: [
          {field: 'id', name: 'Id'},
          {field: 'params.title', name: 'Name', link: 'modules/positions/{id}'},
          {field: 'moduleable_type', name: 'Position Type'},
          {field: 'position', name: 'Position Name'},
          {field: 'state', name: 'Active'},
          {field: 'actions:delete,duplicate', name: '#'},
        ]
      },
      types: [],
      selectedType: null,
      createNewPosition: false

    }
  },
  mounted() {
    this.getList()
    ModuleService.moduleableTypes()
        .then(response => {
          this.types = response.data
        })
        .catch(err => {
          console.log(err)
        })
  },
  methods: {
    getList() {
      this.$store.commit('setLoading', true)
      ModuleService.moduleables(this.filter)
          .then(response => {
            this.moduleables = response.data.data
            this.$store.commit('setLoading', false)
            this.filter.page = response.data.current_page
            this.filter.last_page = response.data.last_page
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            console.log(err)
          })


    },
    page(val) {
      this.filter.page = val.page
      this.filter.limit = val.limit
    },
    actions(action) {
      console.log(action);
      this.$router.push('/modules/positions/create')
    },
    newPosition(val) {
      this.createNewPosition = false
      console.log(val);
    },
    triggerAction(val) {
      switch (val.action) {
        case 'duplicate':
          this.moduleDuplicate(val.id)
          break;
        case 'delete':
          this.moduleDelete(val.id)
          break;
      }
    },
    moduleDuplicate(id) {
      let yn = confirm("Module duplicate. Are you sure?")
      if(!yn){
        return
      }
      ModuleService.moduleableDuplicate(id)
          .then(() => {
            this.getList()
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            console.log(err)
          })
    },
    moduleDelete(id) {
      let yn = confirm("Module delete ID: "+ id +". Are you sure?")
      if(!yn){
        return
      }
      ModuleService.moduleableDelete(id)
          .then(() => {
            this.getList()
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            console.log(err)
          })
    }

  },
  watch: {
    searchTerm() {
      if (typeof window.LIT !== 'undefined') {
        clearTimeout(window.LIT);
      }

      window.LIT = setTimeout(() => {
        this.filter.page = 1
        this.filter.term = this.searchTerm
        this.getList()
      }, 500);
    },
    filter: {
      deep: true,
      handler() {
        this.getList()
      }
    },
    selectedType(val) {
      this.filter.type = val
    }
  }
}
</script>

<style scoped>

</style>