<template>
  <div class="container-fluid">
    <v-tabs
        v-model="tab"
        align-with-title
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab key="positions">
        Module positions
      </v-tab>

      <v-tab key="modules">
        Modules
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="positions">
        <admin-moduleables-list/>
      </v-tab-item>

      <v-tab-item key="modules">
<!--        <admin-module-list/>-->
        In progress
      </v-tab-item>


    </v-tabs-items>
  </div>
</template>

<script>

export default {

  data() {
    return {
      tab: null,
    }
  },
  methods: {

  },

}
</script>

<style scoped>

</style>