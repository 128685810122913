<template>
  <div>
    <div class="row">
      <div class="col-6">
        <v-text-field
            label="Search"
            append-icon="mdi-magnify"
            @input="filterTerm"
            :value="filter.term"
            append-outer-icon="mdi-minus-circle-outline"
            @click:append-outer="clearTerm"
        ></v-text-field>
      </div>
      <div class="col-6">
        <div class="form-label">Category Filter</div>
        <category-dropdown
            :preselected="filter.category"
            @selected="filterCategory"
        />

      </div>
    </div>
    <admin-table v-if="articles.length"
                 ref="adminTable"
                 :header="table.articles_header"
                 :items="articles"
                 :pagination="filter"
                 @page="page"
                 @onNotification="onNotification"
                 @sortBy="sortBy"
    >
    </admin-table>
  </div>

</template>

<script>
import articleService from "@/services/articleService";
import CategoryDropdown from "@/components/category/categoryDropdown.vue";

export default {
  components: {CategoryDropdown},
  data() {
    return {
      articles: [],
      timer: null,
      shouldNotCallApi: false,
      tabIsActive: false,
      hitsInterval: null,
      filter: {
        term: null,
        category: null,
        type: null,
        page: 1,
        limit: 20,
        last_page: 0
      },
      table: {
        articles_header: [
          {field: 'id', name: 'Id', sortable:true},
          {field: 'title', name: 'Name', link: 'articles/{id}', sortable:true},
          {field: 'media', name: 'Media', type:'flag:media', sortable:false},
          {field: 'hits.hits', name: 'Hits', type: 'animate'},
          {field: 'category.title', name: 'Category', type: 'relation', sortable:false},
          {field: 'created_at', name: 'Created', type: 'date', sortable:true},
          {field: 'created_by', name: 'Created By', sortable:true},
          {field: 'updated_at', name: 'Updated', type: 'date', sortable:true},
          {field: 'state', name: 'Active', sortable:true},
          {field: 'sent', name: 'Notification'},
        ]
      },
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {
    let filter = this.$cookies.get("BetaAdmin.ContentFilter")

    this.filter =  filter ?? this.filter
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.tabIsActive = true
    this.getList(true)
  },
  methods: {
    handleVisibilityChange(){
        this.tabIsActive = !document.hidden
    },
    getList(loader) {
      if(!this.tabIsActive){
        return
      }
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      if (loader) {
        this.$store.commit('setLoading', true)
      }
      articleService.list(this.filter)
          .then(res => {
            this.articles = res.data.data
            // this.filter.page = res.data.current_page
            this.filter.last_page = res.data.last_page
            this.$store.commit('setLoading', false)
            this.getHits()
          })
          .catch(err => {
            console.log(err.toString())
            this.$store.commit('setLoading', false)
          })
    },
    getHits() {
      console.log('getHits')
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      this.hitsInterval = setInterval(this.getList, 5000, false)
    },
    page(val) {
      console.log('page')
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      this.filter.page = val.page
      this.filter.limit = val.limit
      this.getList(true)
    },
    onNotification(item) {
      this.$store.commit('setLoading', true)
      articleService.sendNotification(item.id)
          .then(() => {
            item.sent = 1
            this.$store.commit('setLoading', false)
          })
          .catch(() => {
            this.$store.commit('setLoading', false)
          })
    },
    sortBy(item){
      console.log('sortBy')
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      this.filter.sortBy = item
      this.getList(true)
    },
    filterCategory(val){
      console.log('filterCategory')
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      this.filter.category = val
      this.filter.page = 1
      this.getList(true)
    },
    filterTerm(val) {
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.filter.term = val
        this.filter.page = 1
        this.getList(true)
      }, 500);
    },
    clearTerm(){
      clearTimeout(this.hitsInterval)
      clearTimeout(this.timer)
      this.filter.term = ''
      this.getList(true)
    }
  },
  watch: {
    filter:{
      deep: true,
      handler(){
        this.$cookies.set("BetaAdmin.ContentFilter", this.filter)
      }
    }
  }
}
</script>

<style scoped>

</style>