<template>
  <div>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="color"
        elevation="23"
        top
    >
      {{ message }}


    </v-snackbar>
<!--    <v-snackbars-->
<!--        v-show="false"-->
<!--        :messages.sync="messages"-->
<!--        :timeout="5000"-->
<!--        top-->
<!--        center-->
<!--        :color.sync="msgColor"-->
<!--    ></v-snackbars>-->
  </div>
</template>

<script>
// import VSnackbars from "v-snackbars";

export default {
  components: {
    // "v-snackbars": VSnackbars
  },
  data() {
    return {
      // messages: [],
      color: null,
      timeout: 2000,
      snackbar: false,
      message: ''
    }
  },
  watch: {
    '$store.state.messanger': {
      deep: true,
      handler(messanger) {
        if (!messanger.message) {
          return
        }
        this.snackbar = true
        this.timeout = 2000
        this.color = messanger.color
        this.message = false
        this.$nextTick(() => {
          this.message = messanger.message
        })

        // this.messages.push(messanger.message)
      }
    }
  }
}
</script>

<style scoped>

</style>