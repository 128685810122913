export default {
    fileExtension: {
        jpg: {class: 'bi-filetype-jpg', color: '#ff8000'},
        jpeg: {class: 'bi-filetype-jpg', color: '#ff8000'},
        doc: {class: 'bi-file-word-fill', color: 'blue'},
        docx: {class: 'bi-file-word-fill', color: 'blue'},
        xls: {class: 'bi-file-earmark-excel-fill', color: '#009933'},
        xlsx: {class: 'bi-file-earmark-excel-fill', color: '#009933'},
        pdf: {class: 'bi-file-earmark-pdf-fill', color: 'red'},
        ppt: {class: 'bi-file-ppt-fill', color: '0000ff'},
        pptx: {class: 'bi-file-ppt-fill', color: '0000ff'},
        gif: {class: 'bi-filetype-gif', color: 'green'},
        txt: {class: 'bi-filetype-txt', color: '0000ff'},
        csv: {class: 'bi-filetype-txt', color: '0000ff'},
        png: {class: 'bi-filetype-png', color: '#ffcc00'},
        svg: {class: 'bi-filetype-svg', color: '#339933'},
        zip: {class: 'bi-file-earmark-zip', color: '#ffcc00'}
    },
    states: {
        active: {class: 'check', color: '#339933'},
        published: {class: 'check', color: '#339933'},
        1: {class: 'check', color: '#339933'},
        inactive: {class: 'alpha-x', color: '#a10d22'},
        unpublished: {class: 'alpha-x', color: '#a10d22'},
        0: {class: 'alpha-x', color: '#a10d22'}
    },
    actions: {
        delete: {class: 'delete', color: "red", tooltip: "Delete"},
        duplicate: {class: 'content-duplicate', color: "orange", tooltip: "Duplicate"},
        save: {class: 'content-save', color: "green", tooltip: "Save"}
    }
}
