<template>
  <v-app>
    <menu-list v-if="token"/>
    <v-main class="container">
      <v-card elevation="2" class="mt-3">
        <v-card-text>
          <router-view/>
          <admin-messanger/>
          <admin-loading/>
        </v-card-text>
      </v-card>
    </v-main>

  </v-app>
</template>

<script>
import UserService from "@/services/userService";

export default {
  data() {
    return {
      token: localStorage.getItem('token')
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  mounted() {
    UserService.me()
        .catch(() => {
          localStorage.removeItem('token')
          if (this.$route.name !== 'login') {
            this.$router.push('login')
          }
        })
  },
  watch: {
    '$store.state.user'(val) {
      if (!val) {
        localStorage.removeItem('token')

        this.token = null
      }
    }
  }
}
</script>

<style lang="scss">

</style>
