<template>
  <div>

    <v-navigation-drawer
        v-if="active"
        dense
        dark
        app
        clipped
        :mini-variant.sync="mini"
        fixed
        :width="200"
        style="padding-left: 0"
        :mobile-breakpoint="0"
        expand-on-hover>
      <!-- -->
      <template v-slot:prepend>
        <v-list nav dense>
          <div v-for="(item, i) in menu" :key="i">

            <v-list-item
                @click="goTo(item.link)"
                class="v-list-item"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

          </div>
        </v-list>
      </template>

      <template v-slot:append>
        <v-list-group
            key="profile"
            no-action
            :value="false"
            prepend-icon="mdi-account"
        >
          <template v-slot:activator>
            <v-list-item-title>Profile</v-list-item-title>
          </template>
          <v-list-item
              class="v-list-item"
              key="myprofile"
              @click="goTo('/users/' + router.params.id)"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>
          <v-list-item
              class="v-list-item"
              key="logout"
              @click="logout"
          >
            <v-list-item-icon>
              <v-icon>bi bi-box-arrow-up-right</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>

    </v-navigation-drawer>

  </div>
</template>

<script>
import Menu from "@/plugins/menu";
export default {
  data() {
    return {
      menu: Menu,
      showDropdown: false,
      mini: true,
      active: true
    }
  },
  created() {

  },
  methods: {
    goTo(link) {
      window.location.href = link
    },
    logout(){
      localStorage.removeItem('token')
      this.$store.commit('setUser', null)
      this.$router.push('login')
    }
  },
  watch: {
    showDropdown(val) {
      console.log(val)
    }
  }
}
</script>

<style scoped>

</style>