<template>
  <div v-if="item">
    <v-card :elevation="4"
            outlined
            class="h-100 mt-4"

    >
      <v-card-text>
        <div class="row d-flex justify-content-end">
          <div class="col-2">
            <v-btn
                icon
                @click="itemSelected"
            >
              <v-icon v-if="!item.selected">mdi-select</v-icon>
              <v-icon v-else >mdi-checkbox-marked</v-icon>

            </v-btn>
          </div>
        </div>

        <h4 >{{ item.title }}</h4>
        <iframe v-if="showVideo"
                width="100%"
                height="300"
                :src="item.url"  />

        <v-img v-if="!showVideo" :src="item.thumbnail" class="w-100" style="cursor: pointer" @click="showVideo = true"></v-img>
        <div class="mt-5" >
          {{ item.description }}
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  props: ['video'],
  data() {
    return {
      item: this.video,
      showVideo: false
    }
  },
  methods: {
    itemSelected() {
      this.item.selected = !this.item.selected
      this.item.filename = this.item.id
      this.item.id = null
      this.item.type = 3
      this.item.path = 'https://www.youtube.com/embed'
      this.$emit('itemSelected', this.item)
    }
  }
}
</script>

<style scoped>

</style>