<template>
  <div>>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        style="z-index: 10000"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Uploader</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!--            <v-file-input-->
            <!--                v-model="items"-->
            <!--                counter-->
            <!--                multiple-->
            <!--                show-size-->
            <!--                chips-->
            <!--                outlined-->
            <!--                dense-->
            <!--                accept="image/*"-->
            <!--                truncate-length="15"-->
            <!--                @change="filesSelected"-->
            <!--            >-->
            <!--            </v-file-input>-->
            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
              <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  @edit-image="editImage"
                  @mark-is-primary="markIsPrimary"
                  @limit-exceeded="limitExceeded"
                  @before-remove="beforeRemove"
                  id-upload="myIdUpload"
                  id-edit="myIdEdit"
                  :max-image=5
                  primary-text="Default"
                  browse-text="Browse picture(s)"
                  drag-text="Drag pictures"
                  mark-is-primary-text="Set as default"
                  popup-text="This image will be displayed as default"
                  dropText="Drop images here"
                  :multiple=true
                  :show-delete=true
                  :show-add=true
                  :showPrimary=false
              >
              </vue-upload-multiple-image>
            </div>
            <template v-if="selectedImages.length">
              <div class="row">
                <div class="col-12" v-for="(image, index) in selectedImages" :key="index">
                  <div class="row">

                    <div class="col-4">
                      <img :src="image.url" class="w-100">
                    </div>

                    <div class="col-2">
                      <span>{{ image.size|bytesToMB }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-container>
        </v-card-text>


        <v-card-actions>
          <v-btn
              color="blue darken-1"
              text
              @click="upload"
          >
            Upload
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import axios from "axios";

let API = process.env.VUE_APP_API_URL
if (!API) {
  API = "https://beta.rs/api"
}
// const csrf = getCookie('XSRF-TOKEN') ?? ''
const token = localStorage.getItem('token')
let headers = {
  // 'Content-type': 'application/json',
  'Accept': 'application/json',
  // 'Access-Control-Allow-Origin': "https://admin.betadevweb.com"
}
if (token) {
  headers.Authorization = 'Bearer ' + token
}

export default {
  props: ['show', 'content'],
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      items: null,
      dialog: this.show,
      selectedImages: [],
      uploadData: []
    }

  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.$emit('close', this.dialog)
    },
    uploadImageSuccess(formData) {
      this.uploadData.push(formData)
    },
    beforeRemove(index, removeCallBack) {

      this.uploadData.splice(index,1)
      var r = confirm("remove image")
      if (r == true) {
        removeCallBack()
      }
    },
    editImage(formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },
    markIsPrimary(index, fileList) {
      console.log('markIsPrimary data', index, fileList)
    },
    limitExceeded(amount) {
      console.log('limitExceeded data', amount)
    },
    upload(){
      if(!this.uploadData.length){
        return
      }
      let formData = new FormData();
      this.uploadData.forEach( f => {
        formData.append('files[]', f.get('file'))
      })
      this.$store.commit('setLoading', true)
      axios.post('/admin/articles/' + this.content.id + '/upload',
          formData,
          {
            headers: headers,
            baseURL: API,
            xsrfCookieName: 'XSRF-TOKEN',
            params: {
              nocache : 1
            },
          }
      )
          .then(response => {
            response.data.media.forEach( m => {
              m.params = JSON.parse(m.params)
            })
            this.$emit('uploadFinished', response.data)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: 'Upload done', color: 'success'})
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
            this.$store.commit('setLoading', false)
          })
    }
  }
}
</script>


<style scoped>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

.image-container {
  width: 100% !important;
  background-color: red;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>