<template>

  <div>
    <edit-toolbar
        v-if="content"
        :title="content.title"
        buttons="save"
        @save="save"
        @cancel="cancel"
    />

    <v-card v-if="content">

      <v-tabs
          v-model="tab"
          bg-color="primary"
          align-with-title
      >
        <v-tab value="basic">Basic</v-tab>
        <v-tab value="embeds">Embeds</v-tab>
        <v-tab value="media">Media</v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tab">

          <v-tab-item key="basic">
            <basic-content-editor :item="content"
                                  @
            ></basic-content-editor>
          </v-tab-item>

          <v-tab-item key="embeds">
            <content-embed v-if="content.params.embeds" :embeds="content.params.embeds" :content="content"
                           @embedChanged="embedChanged"></content-embed>
          </v-tab-item>

          <v-tab-item key="media">
            <content-media-list v-if="content"
                                :content="content"
                                @update="updateMedia"
            >

            </content-media-list>
          </v-tab-item>


        </v-tabs-items>


      </v-card-text>
    </v-card>
  </div>


</template>

<style scoped>

</style>
<script>
import EditToolbar from "@/components/toolbar/EditToolbar.vue";
import ArticleService from "@/services/articleService";
import BasicContentEditor from "@/components/article/BasicContentEditor.vue";
import ContentEmbed from "@/components/article/ContentEmbed.vue";
// import CategoryDropdown from "@/components/category/categoryDropdown.vue";
export default {
  components: {ContentEmbed, BasicContentEditor, EditToolbar},
  data() {
    return {
      content: null,
      tab: null,
      embeds: []
    }
  },
  mounted() {
    this.getArticle()
  },
  methods: {
    getArticle() {
      this.$store.commit('setLoading', true)
      ArticleService.get(this.$route.params.id)
          .then((response) => {
            this.content = response.data
            if (!this.content.params) {
              this.content.params = {}

            }
            this.content.params.embeds = this.content.params.embeds ?? []
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    save() {
      this.$store.commit('setLoading', true)
      ArticleService.update(this.content)
          .then((response) => {
            this.content = null
            this.$nextTick(() => {
              this.content = response.data
            })

            this.$store.commit('setMessage', {text: "Saved", color: 'success'})
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.response.data, color: 'danger'})
          })
    },
    cancel() {
      this.$router.push('/articles')
    },
    embedChanged(data) {
      this.content.params.embeds = data;
    },
    updateMedia(item) {
      this.content = item
    }
  },
  watch:{
    content(){

    }
  }
}
</script>