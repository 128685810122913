<template>
  <v-card flat v-if="moduleList">
    <v-card-text>
      <!-- #MODULES -->
        <v-select :items="moduleList"
                  item-text="title"
                  item-value="id"
                  v-model="selectedModule"
                  dense
                  return-object
                  label="Select Module"
        ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
import ModuleService from "@/services/moduleService";

export default {
  props:[
      'preselected'
  ],
  data() {
    return {
      moduleList: null,
      selectedModule: this.preselected,
      filter:{
        page:1,
        limit:10000
      }

    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {

      ModuleService.list(this.filter)
          .then(response => {
            this.moduleList = response.data.data
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })

    }
  },
  watch: {
    selectedModule:{
      deep:true,
      handler(module){
        this.$emit('selected', module)
      }
    }
  }
}
</script>

<style scoped>

</style>