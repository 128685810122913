import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import VueRouter from 'vue-router'
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Vuex from 'vuex'
import store from './plugins/store'
import AxiosPlugin from 'vue-axios-cors';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(AxiosPlugin)
Vue.use(require('vue-moment'))
Vue.use(VueCookies)

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


let stopCss = "color:red; font-size:65px; font-weight:bold; -webkit-text-stroke: 1px black";
let msgCss = "font-size:20px; ";

console.log('%cStop', stopCss);
console.log('%cThis feature is intended for Developers only.', msgCss);

Vue.filter('bytesToMB', function (bytes) {
  if (bytes === 0) return '0 MB';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
});

new Vue({
  vuetify,
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
