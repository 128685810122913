<template>

  <div class="mt-3">
    <v-card v-if="itemsData.length">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>

        <div class="row" v-for="(item, key) in itemsData" :key="key">
          <div class="col-10">
            <div class="row">
              <div class="col-4">
                <v-text-field
                    v-model="item.name"
                    label="Field Name"
                    :key="key"/>
              </div>
              <div class="col-8">
                <v-text-field
                    v-if="checkLength(item.value)"
                    v-model="item.value"
                    label="Field Value"
                    :key="key"/>

                <v-textarea
                    v-else
                    :id="item.name"
                    v-model="item.value"
                    :name="item.name"
                    :label="item.name"
                ></v-textarea>
              </div>
            </div>


          </div>
          <div class="col-2">
            <v-btn icon
                   elevation="2"
                   @click.prevent="addField"
            >
              <v-icon color="green">mdi-link-plus</v-icon>
            </v-btn>

          </div>

        </div>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'items'
  ],
  data() {
    return {
      itemsData: []
    }
  },
  computed: {
    uuid() {
      return Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
    }
  },
  mounted() {
    this.resolveFields()
  },
  methods: {
    resolveFields() {
      if (!this.items) {
        this.addField()
        return
      }
      if (Object.keys(this.items).length < 1) {
        this.addField()
        return
      }

      Object.keys(this.items).forEach(key => {
        this.itemsData.push({
          name: key,
          value: this.items[key]
        })
      })

      this.resolving = false
    },
    addField() {

      this.itemsData.push({
        name: '',
        value: ''
      })
    },
    checkLength(item) {
      item = item + ''
      return item.length < 50
    }
  },
  watch: {
    itemsData: {
      deep: true,
      handler(items) {
        let resolved = {}
        items.forEach( item => {
          Object.assign(resolved, {[item.name]: item.value})
        })
        this.$emit('result', resolved)
      }
    }
  }
}
</script>

<style scoped>

</style>