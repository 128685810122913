<template>
  <div>
    <v-card v-if="moduleable">
      <edit-toolbar :title="''"
                    buttons="save"
                    @save="save"
                    @cancel="cancel"
      />
      <v-card-text>

        <div class="row">
          <div class="col-3">
            <module-dropdown :preselected="moduleable.module"
                             @selected="selectedModule"

            />
          </div>
          <div class="col-2">
            <v-select
                v-if="moduleable.module_id"
                :items="types"
                v-model="selectedType"
                label="Select Type"
            />
            <category-dropdown v-if="selectedType === 'category'  || selectedType === 'content'"
                               @selected="isCategory"
            />

            <menu-dropdown v-if="selectedType === 'menu'"
                           @selected="isMenu"/>
          </div>
          <div class="col-2">
            <v-select v-if="selectedType"
                      :items="positions"
                      v-model="selectedPosition"/>
          </div>
          <div class="col-2">
            <v-text-field v-if="selectedPosition"
                          v-model="moduleable.ordering"
                          label="Ordering"
            />
          </div>
          <div class="col-1 justify-content-end">
            <v-switch v-model="state" label="State"/>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <repeatable-fields v-if="moduleable.params"
                       title="Params"
                       :items="moduleable.params"
                       @result="handleParams"
    />

  </div>
</template>

<script>

import RepeatableFields from "@/components/toolbar/repeatableFields";
import EditToolbar from "@/components/toolbar/EditToolbar";
import ModuleService from "@/services/moduleService";
import CategoryDropdown from "@/components/category/categoryDropdown";
import MenuDropdown from "@/components/category/menuDropdown";

export default {
  components: {MenuDropdown, CategoryDropdown, EditToolbar, RepeatableFields},
  data() {
    return {
      moduleable: {
        params: {}
      },
      types: null,
      positions: null,
      selectedType: null,
      selectedPosition: null,
      params: null,
      predefinedFields: null
    }
  },
  computed: {
    state: {
      get() {
        return this.moduleable.state === 1 ? true : false
      },
      set(val) {
        this.moduleable.state = val ? 1 : 0
      }
    }
  },
  mounted() {
    this.initModuleable()
    this.loadModuleable()
  },
  methods: {
    loadModuleable() {
      ModuleService.moduleableTypes()
          .then(response => {
            this.types = response.data
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })


    },
    handleParams(params) {
      this.moduleable.params = params
    },
    initModuleable() {
      this.moduleable = {
        id: null,
        module_id: null,
        moduleable_id: null,
        moduleable_type: null,
        position: null,
        ordering: null,
        params: null,
        state: 0,
        module: {
          title: ''
        }
      }
    },
    isCategory(val) {
      this.moduleable.moduleable_id = val
    },
    isMenu(val) {
      console.log(val);
      this.moduleable.moduleable_id = val
    },
    selectedModule(module) {
      this.moduleable.module = module
      this.moduleable.module_id = module.id

    },
    save() {
      this.$store.commit('setLoading', true)
      ModuleService.moduleablePositionsSave(this.moduleable)
          .then((res) => {
            this.$store.commit('setMessage', {text: "Saved", color: 'success'})
            this.$store.commit('setLoading', false)
            this.$router.push('/modules/positions/' + res.data.id)
          })
          .catch( err => {
            console.log(err)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    cancel() {
      this.$router.push('/modules')
    }
  },
  watch: {
    selectedType(val) {
      ModuleService.moduleablePositions({type: val})
          .then(response => {
            this.positions = response.data
            // this.selectedPosition = this.moduleable.position
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
      this.moduleable.moduleable_type = val
    },
    selectedPosition(val) {
      this.moduleable.position = val
    }
  }
}
</script>

<style scoped>

</style>