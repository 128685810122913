<template>
  <div>
    <edit-toolbar title="Modules"
                  buttons="save"

    ></edit-toolbar>
    <div class="row">
      <!--      <div class="col-4">-->

      <!--        <v-tabs-->
      <!--            v-model="tab"-->
      <!--            background-color="transparent"-->
      <!--            color="basil"-->
      <!--            grow-->
      <!--        >-->
      <!--          <v-tab key="categories">Categories</v-tab>-->
      <!--          <v-tab key="menu">Menu</v-tab>-->
      <!--          <v-tabs-items v-model="tab">-->
      <!--            <v-tab-item key="categories">-->
      <!--              <category-tree-->
      <!--                  v-if="module"-->
      <!--                  :preselected="preselectedCategories"-->
      <!--              ></category-tree>-->
      <!--            </v-tab-item>-->
      <!--            <v-tab-item key="menu">-->
      <!--              <menu-tree-->
      <!--                  v-if="module"-->
      <!--                  :preselected="preselectedMenu">-->
      <!--              </menu-tree>-->
      <!--            </v-tab-item>-->
      <!--          </v-tabs-items>-->
      <!--        </v-tabs>-->
      <!--      </div>-->
      <div class="col-6">

        <v-card v-if="module">
          <v-card-text>

            <div class="row">
              <div class="col-10">
                <v-text-field
                    label="Module title"
                    hide-details="auto"
                    v-model="module.title"
                ></v-text-field>
              </div>
              <div class="col-2">
                <v-switch
                    v-model="module.state"
                    label="Active"
                ></v-switch>
              </div>

              <div class="col-12">
                <v-text-field
                    label="Module path"
                    hide-details="auto"
                    v-model="module.module_path"
                ></v-text-field>
              </div>

            </div>

            <div class="row" v-if="moduleParams.length">
              <div class="col-12"
                   v-for="(param, i) in moduleParams"
                   :key="i"
              >
                <v-text-field
                    :label="param"

                    hide-details="auto"
                    v-model="module.params[i]"
                ></v-text-field>
              </div>
            </div>

          </v-card-text>
        </v-card>
        {{ module }}
      </div>
      <div class="col-6">
        <admin-expansion-panel v-if="panel"
                               :title="panel"
                               :items="panel"
        />

      </div>

    </div>
  </div>
</template>

<script>
import ModuleService from "@/services/moduleService";

import EditToolbar from "@/components/toolbar/EditToolbar";
import AdminExpansionPanel from "@/components/modules/AdminExpansionPanel";

export default {
  components: {AdminExpansionPanel, EditToolbar},
  data() {
    return {
      module: null,
      preselectedCategories: [],
      preselectedMenu: [],
      tab: null,
      moduleState: false,
      moduleParams: [],
      panel: null
    }
  },
  mounted() {
    this.getModule()
  },
  methods: {
    getModule() {
      ModuleService.get(this.$route.params.id)
          .then(response => {
            this.module = response.data
            this.moduleState = this.module.state === 1 ? true : false
            this.module.category.forEach(category => {
              this.preselectedCategories.push(category.id)
            })
            this.module.menu.forEach(category => {
              this.preselectedMenu.push(category.id)
            })
            this.moduleParams = this.module.params
            this.panel = [
              {title: 'Categories', items: this.module.category},
              {title: 'Menu', items: this.module.menu},
            ]
          })
          .catch(err => {
            console.log(err);
          })
    }
  }

}
</script>

<style scoped>

</style>