<template>
  <div>
    <v-expansion-panels v-if="itemsData">
      <v-expansion-panel
          v-for="(item,i) in itemsData"
          :key="i"
      >
        <v-expansion-panel-header>
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.items">

          <v-card>
            <v-expansion-panels v-if="item.items">
              <v-expansion-panel
                  v-for="(cm,k) in item.items"
                  :key="k"
                  :expand="true"
              >
                <v-expansion-panel-header>
                  {{ cm.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{cm}}
                  <repeatable-fields v-if="cm.pivot"
                      :items="cm.pivot.params"/>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>

</template>

<script>
import RepeatableFields from "@/components/toolbar/repeatableFields";
export default {
  components: {RepeatableFields},
  props: [
    'items',
    'title'
  ],
  data() {
    return {
      itemsData: this.items
    }
  },
  mounted() {
    console.log(this.itemsData);
  }
}
</script>

<style scoped>

</style>