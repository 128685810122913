<template>
  <div class="row justify-content-center">
    <div class="col-4">
      <div class="card">
        <div class="card-body">

          <div class="form-group">
            <label for="username" class="text-info">Username:</label><br>
            <input type="text"
                   v-model="username"
                   id="username"
                   class="form-control"
                   @keydown="login"
            >
          </div>
          <div class="form-group">
            <label for="password" class="text-info">Password:</label><br>
            <input
                type="password"
                v-model="password"
                id="password"
                class="form-control"
                @keydown="login"
            >
          </div>
        </div>

        <div class="mb-3 text-center">
          <button v-on:click.stop.prevent="submit"
                  class="btn btn-dark w-25"
          >Login
          </button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import UserService from "@/services/userService";

export default {
  data() {
    return {
      username: null,
      password: null
    }
  },
  methods: {
    submit() {
      UserService.login({
        email: this.username,
        password: this.password
      })
          .then(response => {
            this.$store.commit('setUser', response.data.data)
            localStorage.setItem('token', response.data.data.token)
            window.location.href = '/dashboard'
          })
          .catch((err) => {
            console.log(err);
          })
    },
    login(pressed){
      if(pressed.key === 'Enter'){
        this.submit()
      }
    }
  }
}
</script>

<style scoped>

</style>