<template>

    <div class="row">
      <div class="col-12 text-right">

        <v-btn @click="createNew">
          Create
        </v-btn>

      </div>
    </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  mounted(){

  },
  methods:{
    createNew(){
      this.$emit('action', 'createNew')
    }
  }
}
</script>

<style scoped>

</style>