<template>
  <div>
    <v-card :elevation="4"
            outlined
            class="h-100 mt-4"

    >
      <v-card-text>
        <div class="row d-flex justify-content-end">
          <div class="col-2">
            <v-btn
                icon
                @click="select"
            >
              <v-icon v-if="!item.selected">mdi-select</v-icon>
              <v-icon v-else >mdi-checkbox-marked</v-icon>

            </v-btn>
          </div>
        </div>

        <h4 >{{ item.naslov }}</h4>
        <video v-if="showVideo" class="w-100" controls :src="item.url"></video>
        <v-img v-if="!showVideo" :src="item.cover" class="w-100" style="cursor: pointer" @click="showVideo = true"></v-img>
        <div class="mt-5" >
          {{ item.vest }}
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  props: ['video'],
  data() {
    return {
      item: this.video,
      showVideo: false
    }
  },
  methods: {
    select() {
      this.item.selected = !this.item.selected
      this.item.type = 2
      this.$emit('select', this.item)
    }
  }
}
</script>

<style scoped>

</style>