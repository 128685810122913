<template>
  <v-card flat>
    <v-card-text>
      <!-- #MODULES -->
      <admin-table v-if="moduleList.length"
                   :header="table.header"
                   :items="moduleList"
                   :pagination="filter"
                   @page="page"
      >

      </admin-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ModuleService from "@/services/moduleService";
import AdminTable from "@/components/AdminTable";

export default {
  components: {AdminTable},
  data() {
    return {
      moduleList: [],
      filter: {
        term: null,
        page: 1,
        limit: 20,
        last_page: 0
      },
      table: {
        header: [
          {field: 'id', name: 'Id'},
          {field: 'title', name: 'Name', link: 'modules/{id}'},
          {field: 'state', name: 'Active'},
          {field: 'created_at', name: 'Created at'},
        ]
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$store.commit('setLoading', true)
      ModuleService.list(this.filter)
          .then(response => {
            this.moduleList = []
            this.moduleList = response.data.data
            this.filter.page = response.data.current_page
            this.filter.last_page = response.data.last_page
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })

    },
    page(val) {
      this.filter.page = val.page
      this.filter.limit = val.limit
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getList()
      }
    }
  }
}
</script>

<style scoped>

</style>