<template>

      <v-toolbar dense class="mb-3">
        <v-toolbar-title>{{  title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn large icon v-if="showButtons.save" @click.prevent="$emit('save')">
          <v-icon color="green">mdi-content-save</v-icon>
        </v-btn>

        <v-btn large icon v-if="showButtons.delete" @click.prevent="$emit('delete')">
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>

        <v-btn large icon v-if="showButtons.search">
          <v-icon color="blue">mdi-magnify</v-icon>
        </v-btn>

        <v-btn large icon @click.prevent="$emit('cancel')">
          <v-icon color="red">mdi-close-box</v-icon>
        </v-btn>

      </v-toolbar>

<!--  <div class="row mb-5">-->
<!--    <div class="col-6">-->
<!--      <h4>{{ title }}</h4>-->
<!--    </div>-->
<!--    <div class="col-6">-->
<!--      <div class="row justify-content-end no-gutters">-->

<!--        <div class="col-1" v-if="showButtons.save">-->
<!--          <v-btn large-->
<!--                 icon-->
<!--                 elevation="2"-->
<!--                 @click.prevent="$emit('save')"-->
<!--          >-->
<!--            <v-icon color="green">mdi-content-save</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->

<!--        <div class="col-1" v-if="showButtons.delete">-->
<!--            <v-btn large-->
<!--                   icon-->
<!--                   elevation="2"-->
<!--                   @click.prevent="$emit('delete')"-->
<!--            >-->
<!--            <v-icon color="red">mdi-delete</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->

<!--        <div class="col-1" v-if="showButtons.search">-->
<!--          <v-btn>-->
<!--            <v-icon color="blue">mdi-magnify</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->

<!--        <div class="col-1">-->
<!--            <v-btn large-->
<!--                   icon-->
<!--                   elevation="2"-->
<!--                   @click.prevent="$emit('cancel')"-->
<!--            >-->
<!--            <v-icon color="red">mdi-close-box</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->

<!--      </div>-->


<!--    </div>-->


<!--  </div>-->
</template>

<script>
export default {
  props: [
    'title',
    'buttons'
  ],
  data() {
    return {
      showButtons: {
        save: false,
        delete: false,
        search: false
      }
    }
  },
  mounted() {
    if (this.buttons) {
      let btns = this.buttons.split(',')
      btns.forEach(b => {
        this.showButtons[b] = true
      })
    }
  }
}
</script>

<style scoped>

</style>