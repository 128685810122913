<template>
  <div>
    <edit-toolbar
        v-if="moduleable"
        :title="moduleTitle"
        buttons="save"
        @save="save"
        @cancel="cancel"
    />

    <v-card v-if="moduleable">
      <v-card-text>

        <div class="row">
          <div class="col-6">
            <v-text-field v-model="moduleable.params.title"
                          label="Title"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <module-dropdown :preselected="moduleable.module"
                             @selected="selectedModule"

            />
          </div>
          <div class="col-2">
            <v-select
                v-if="moduleable.module_id"
                :items="types"
                v-model="selectedType"
                label="Select Type"
            />

            <category-dropdown v-if="selectedType === 'category'  || selectedType === 'content'"
                               :preselected="moduleable.moduleable_id"
                               @selected="isCategory"
            />

            <menu-dropdown v-if="selectedType === 'menu'"
                           :preselected="moduleable.moduleable_id"
                           @selected="isMenu"/>
          </div>
          <div class="col-2">
            <!--            <v-select v-if="selectedType"-->
            <!--                      :items="positions"-->
            <!--                      v-model="selectedPosition"/>-->
            <v-combobox v-if="positions"
                        :items="positions"
                        v-model="selectedPosition"
                        hide-selected
            ></v-combobox>
          </div>
          <div class="col-2">
            <v-text-field v-if="moduleable.ordering"
                          v-model="moduleable.ordering"
                          label="Ordering"
            />
          </div>
          <div class="col-1 justify-content-end">
            <v-switch v-model="state" label="State"/>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <repeatable-fields v-if="moduleable"
                       title="Params"
                       :items="moduleable.params"
                       @result="handleParams"
    />
  </div>
</template>

<script>
import RepeatableFields from "@/components/toolbar/repeatableFields";
import EditToolbar from "@/components/toolbar/EditToolbar";
import ModuleService from "@/services/moduleService";
import CategoryDropdown from "@/components/category/categoryDropdown";
import MenuDropdown from "@/components/category/menuDropdown";

export default {
  components: {MenuDropdown, CategoryDropdown, EditToolbar, RepeatableFields},
  data() {
    return {
      moduleable: null,
      types: null,
      positions: null,
      selectedType: null,
      selectedPosition: null,
      params: null,
      predefinedFields: null,
    }
  },
  computed: {
    state: {
      get() {
        return this.moduleable.state === 1 ? true : false
      },
      set(val) {
        this.moduleable.state = val ? 1 : 0
      }
    },
    moduleTitle() {
      return this.moduleable?.params?.title ?? ''
    }
  },
  mounted() {
    this.loadModuleable()
  },
  methods: {
    loadModuleable() {
      ModuleService.moduleable(this.$route.params.id)
          .then(response => {
            this.moduleable = response.data
            this.selectedType = this.moduleable.moduleable_type

            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })

      ModuleService.moduleableTypes()
          .then(response => {
            this.types = response.data
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })

      ModuleService.moduleablePositions()
          .then(response => {
            this.positions = response.data
            this.selectedPosition = this.moduleable.position
          })
          .catch(err => {
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
    },
    handleParams(params) {
      this.moduleable.params = params
    },
    isCategory(val) {
        this.moduleable.moduleable_id = val
    },
    isMenu(val) {
      console.log(val);
      this.moduleable.moduleable_id = val
    },
    selectedModule(module) {
      this.moduleable.module = module
      this.moduleable.module_id = module.id

    },
    save() {

      this.$store.commit('setLoading', true)
      ModuleService.moduleablePositionsUpdate(this.moduleable)
          .then((response) => {
            this.moduleable = response.data
            this.$store.commit('setMessage', {text: "Saved", color: 'success'})
            this.$store.commit('setLoading', false)
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setLoading', false)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })

    },
    cancel() {
      this.$router.push('/modules')
    }
  },
  watch: {
    selectedType(val) {
      ModuleService.moduleablePositions({type: val})
          .then(response => {
            this.positions = response.data
            // this.selectedPosition = this.moduleable.position
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('setMessage', {text: err.toString(), color: 'danger'})
          })
      this.moduleable.moduleable_type = val
    },
    selectedPosition(val) {
      this.moduleable.position = val
    }
  }
}
</script>

<style scoped>

</style>