<template>
  <div>
    <admin-table v-if="userList"
                 :header="table.header"
                 :items="userList"
                 :pagination="filter"
                 @page="page"
    >

    </admin-table>
  </div>
</template>

<script>
import RssUserService from "@/services/rssUserService";
import AdminTable from "@/components/AdminTable.vue";

export default {
  components: {AdminTable},
  data() {
    return {
      userList: null,
      filter: {
        term: null,
        page: 1,
        limit: 20,
        last_page: 0
      },
      table: {
        header: [
          {field: 'id', name: 'Id'},
          {field: 'user', name: 'Name', link: 'rssUsers/{id}'},
          {field: 'state', name: 'Active'},
          {field: 'created_at', name: 'Created at'},
        ]
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$store.commit('setLoading', true)
      RssUserService.list(this.filter)
          .then(response => {
            this.$store.commit('setLoading', false)
            this.userList = response.data.data
            this.filter.page = response.data.current_page
            this.filter.last_page = response.data.last_page
          })
          .catch(err => {
            this.$store.commit('setLoading', false)
            console.log(err)
          })
    },
    page(val) {
      this.filter.page = val.page
      this.filter.limit = val.limit
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getList()
      }
    }
  }
}
</script>

