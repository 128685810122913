<template>

  <v-treeview
      v-if="selected"
      open-on-click
      return-object
      expand-icon="mdi-chevron-down"
      indeterminate-icon="mdi-bookmark-minus"
      dense
      :items="menu"
  >
    <template v-slot:label="props">
      <div class="treeCheckBox">
        <v-checkbox class="treeCheckBox"
                    color="indigo"
                    v-model="props.item.selected"
                    :label="props.item.name">
        </v-checkbox>
      </div>
    </template>

  </v-treeview>
</template>

<script>
import MenuService from "@/services/menuService";

export default {
  props: {
    preselected:{
      type: Array,
      require: true
    }
  },
  data() {
    return {
      menu: [],
      selected: this.preselected
    }
  },
  mounted() {
    this.getmenu()
  },
  methods: {
    getmenu() {
      MenuService.list({tree: true})
          .then(response => {
            let menu = response.data.data
            this.menu = []
            menu.forEach(menu => {

              if (menu.parent_id === 0) {
                let cat = {
                  id: menu.id,
                  name: menu.title,
                  children: [],
                  selected: this.preselected.includes(menu.id)
                }
                if (menu.children.length) {
                  menu.children.forEach(child => {
                    let c = {
                      id: child.id,
                      name: child.title,
                      selected: this.preselected.includes(child.id)
                    }
                    cat.children.push(c)
                  })
                }
                this.menu.push(cat)
              }
            })
          })
    }
  }
}
</script>

<style scoped>
.treeCheckBox {
  margin-top: 0px;
  max-height: 30px;
}
</style>