<template>
  <div>
    <template v-if="embedData">
      <v-card elevation="2"
              class="mt-3"
              outlined
              v-for="(embed, key) in embedData" :key="key"
      >
        <v-card-text>
          <div class="row">

            <div class="col-12 col-md-3">
              <v-select v-if="paragraphCounter"
                        :items="paragraphCounter"
                        v-model="embed.paragraph"
                        :key="key"
                        label="Select paragraph number"
              />


            </div>

            <div class="col-10 col-md-8">
              <v-textarea
                  v-model="embed.embed"
                  label="Embed"
                  :key="key"/>
            </div>
            <div class="col-2 col-md-1">
              <v-icon color="green" large style="cursor: pointer" @click="add(key)">mdi-plus-box</v-icon>
              <v-icon color="red" large style="cursor: pointer" @click="remove(key)">mdi-trash-can</v-icon>
            </div>
          </div>
        </v-card-text>
      </v-card>

    </template>
  </div>
</template>

<script>


import articleService from "@/services/articleService";

export default {
  props: ['embeds', 'content'],
  data() {
    return {
      embedData: null,
      paragraphCounter: null

    }
  },
  mounted() {
    let embeds = this.embeds
    if (embeds.length < 1) {
      embeds = [
        {
          'paragraph': null,
          'embed': null
        }
      ];
    }
    this.embedData = embeds

    articleService.getParagraphs(this.content.id)
        .then((res) => {
          let paragraphs = res.data.paragraphs
          this.paragraphCounter = []
          paragraphs.forEach( (p,k)  => {
            this.paragraphCounter.push(k)
          })
        })
        .catch( err => {
          console.log(err.toString())
        })
  },
  methods: {
    add(key) {
      this.embedData.splice(key + 1, 0, {
        'paragraph': null,
        'embed': null
      });
    },
    remove(key) {
      this.embedData.splice(key, 1)
      if (this.embedData.length < 1) {
        this.embedData.push({
          'paragraph': null,
          'embed': null
        })
      }
    }
  },
  watch: {
    embedData: {
      deep: true,
      handler() {
        this.$emit('embedChanged', this.embedData)
      }
    }
  }
}
</script>


<style scoped>

</style>