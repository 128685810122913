<template>
  <section>
    <v-overlay :value="overlay" style="z-index: 1000000000000000">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>


export default {
  data(){
    return {
      overlay: false
    }
  },
  watch: {
    '$store.state.loading': {
      deep: true,
      handler(loading) {
        this.overlay = loading
      }
    },
  }
}
</script>

<style scoped>

</style>