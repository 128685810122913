import axios from 'axios'
let API = process.env.VUE_APP_API_URL
if(!API){
    API = "https://beta.rs/api"
}
// const csrf = getCookie('XSRF-TOKEN') ?? ''
const token = localStorage.getItem('token')
let headers = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    // 'Access-Control-Allow-Origin': "https://admin.betadevweb.com"
}
if(token){
    headers.Authorization = 'Bearer ' + token
}
let instance = axios.create({
    // withCredentials: true,
    // crossorigin: true,
    // credentials: "cross-origin",
    headers: headers,
    baseURL: API,
    xsrfCookieName: 'XSRF-TOKEN',
    params: {
        nocache : 1
    },
});


// function getCookie(name) {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
// }

export default instance;
